<template>
  <div>
    <el-button type="primary" @click="exportPDF('showTotal')" size="medium"
      >Download the certificate</el-button
    >
    <div id="showTotal" v-if="project_code == '22-23EI'">
      <div v-for="(item, index) in awards" :key="index" class="certContainer13">
        <img src="../assets/22-23EI.png" class="model" />

        <div class="student">
          <span class="name">{{ `${upfirstName(item.student_name)} ` }}</span>
        </div>

        <div class="event" v-html="item.application_event"></div>

        <div class="award">{{ item.award_name }}</div>
      </div>
    </div>
  </div>
</template>
  <script>
import { getStudentAwards } from "../service.js";
import Cookie from "js-cookie";
export default {
  data() {
    return {
      project_code: Cookie.get("loginProject"),
      awards: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let user_id = Cookie.get("loginId");
      let project_code = Cookie.get("loginProject");
      getStudentAwards(user_id, project_code).then((res) => {
        this.awards = res.data.data;
        // this.$nextTick(()=>{
        //   this.exportPDF('showTotal')
        // })
      });
    },

    formatEvent(event) {
      if (
        event ==
        "Internet Usage and its Impact on the Quality of Life among Elderly Individuals: An Empirical Study in Shanghai"
      ) {
        return "Internet Usage and its Impact on the Quality of <br>Life among Elderly Individuals: An Empirical Study in Shanghai";
      }
      if (
        event ==
        "Affective Responses to Competitive Video Gaming: An Exploration of Emotional Evocations and Regulation Strategies in the Adolescent Population"
      ) {
        return "Affective Responses to Competitive Video Gaming: An Exploration of<br> Emotional Evocations and Regulation Strategies in the Adolescent Population";
      }
      if (
        event ==
        "Acquisition of Knowledge on Eugenics by families of Children with OroFacial Cleft in the Yushu Region"
      ) {
        return "Acquisition of Knowledge on Eugenics by families of <br> Children with OroFacial Cleft in the Yushu Region";
      }
      if (
        event ==
        "The Impact of School Bullying on Adolescent Mental Health in Different Dimensions"
      ) {
        return "The Impact of School Bullying on Adolescent <br>Mental Health in Different Dimensions";
      }
      if (
        event ==
        "Career Development Challenges of Contemporary Factory Female Workers"
      ) {
        return "Career Development Challenges of <br>Contemporary Factory Female Workers";
      }
      return event;
    },

    upfirstName(name) {
      return name.toLowerCase().replace(/\b([\w|‘]+)\b/g, function (word) {
        // return word.slice(0, 1).toUpperCase() + word.slice(1);
        return word.replace(word.charAt(0), word.charAt(0).toUpperCase());
      });
    },
    exportPDF(id) {
      var style = document.createElement("style");

      style.innerHTML = "@page{size:A4 portrait;margin: auto 0mm}";
      document.getElementsByTagName("body")[0].style.zoom = 1;
      window.document.head.appendChild(style);
      const printData = document.getElementById(id).innerHTML;
      this.PageSetup_Null(); // 把页眉页脚设置为空
      window.document.body.innerHTML = printData; // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
      window.print(); // 开始打印
    },
    PageSetup_Null() {
      var HKEY_Root, HKEY_Path, HKEY_Key;

      HKEY_Root = "HKEY_CURRENT_USER";

      HKEY_Path = "\\Software\\Microsoft\\Internet Explorer\\PageSetup\\";

      try {
        var Wsh = new ActiveXObject("WScript.Shell");

        HKEY_Key = "header";

        Wsh.RegWrite(HKEY_Root + HKEY_Path + HKEY_Key, "");

        HKEY_Key = "footer";

        Wsh.RegWrite(HKEY_Root + HKEY_Path + HKEY_Key, "");


        HKEY_Key="margin_bottom";  
        Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"0");  

        HKEY_Key="margin_left";  
        Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"0");  

        HKEY_Key="margin_right";  
        Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"0");

        HKEY_Key="margin_top";  
        Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"0"); 
      } catch (e) {}
    },
  },
};
</script>
  <style scoped lang="scss">
.certContainer13 {
  position: relative;
  color: black;
  width: 210mm;

  .date {
    bottom: 230px;
    left: 150px;
    position: absolute;
    font-size: 18px;
    text-align: center;
    line-height: 26px;
  }
  .date1 {
    bottom: 190px;
    right: 100px;
    position: absolute;
    font-size: 18px;
    text-align: center;
    line-height: 26px;
  }

  .text1 {
    position: absolute;
    font-size: 18px;
    top: 330px;
    width: 210mm;
    color: #333333;
    text-align: center;
  }
  .model {
    width: 210mm;
    height: 295mm;
  }
  .event {
    position: absolute;
    font-size: 15px;
    top: 485px;
    text-align: center;
    font-weight: 500;
    line-height: 26px;
    width: 210mm;
    font-style: italic;
    color: black;
  }

  .award {
    position: absolute;
    font-size: 28px;
    top: 600px;
    font-weight: 600;
    width: 210mm;
    color: #d30b27;
    text-align: center;
  }

  .student {
    position: absolute;
    top: 390px;
    width: 210mm;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    color: black;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 210mm;
    }
  }
}

// @media print {
//   div {
//     //webkit 为Google Chrome、Safari等浏览器内核
//     -webkit-print-color-adjust: exact;
//     color-adjust: exact;
//   }
//   .award {
//     color: #ffb411 !important;
//   }
// }
</style>
  